





























































import { Component, Vue } from 'vue-property-decorator'
import ContentPreview from '@/components/content/ContentPreview.vue'
import NewsPreview from '@/components/content/NewsPreview.vue'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'

import NewsEventsAPI from '@/api/requests/content'
import { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import { contentStatus } from '@/config/content/contentStatus'
import { newsOnPage, eventsOnPage } from '@/config/content/contentCount'
import { userMapper } from '@/store/modules/user'

SwiperCore.use([Navigation, Pagination])

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['isTeacher', 'isContentManager'])
  }
})
@Component({
  components: {
    ContentPreview,
    NewsPreview,
    Swiper,
    SwiperSlide
  }
})
export default class Feed extends Mapper {
  private eventsData: object[] = []
  private newsData: object[] = []

  private changeDisplayStatus(contentObjects: object[]) {
    contentObjects.forEach((element: any) => {
      element.status = false
    })
  }

  private async created() {
    this.eventsData = (
      await NewsEventsAPI.getEvents(
        eventsOnPage,
        0,
        this.isTeacher && !this.isContentManager
          ? contentStatus.all
          : contentStatus.publicated
      )
    ).data.rows
    this.changeDisplayStatus(this.eventsData)

    this.newsData = (
      await NewsEventsAPI.getNews(newsOnPage, 0, contentStatus.publicated)
    ).data.rows
    this.changeDisplayStatus(this.newsData)
  }

  private swiperComponentOption: SwiperOptions = {
    slidesPerView: 1,
    slidesPerGroup: 1,
    breakpoints: {
      450: {
        slidesPerView: 2
      },
      991: {
        slidesPerView: 4
      }
    },
    shortSwipes: true,
    followFinger: true,
    simulateTouch: true,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  onNewsButtonClick() {
    this.$router.push(`/news`)
  }
  onEventsButtonClick() {
    this.$router.push(`/events`)
  }
}
